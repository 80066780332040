import React, {useState, useEffect} from "react";
import {Link, Trans, useTranslation, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import Settings from '../../../constants/company/settings';
import ContinentSettings from '../../../constants/continents/geo-continents';
import InfoCardCurrent from '../../../components/contact/info-card-current';
import hubspotSettings from "../../../constants/company/settings";
import Cookies from "js-cookie";
import {StaticImage} from "gatsby-plugin-image";

const IndexPage = (props) => {
  const isBrowser = typeof window !== "undefined";
  const {t} = useTranslation();
  const {changeLanguage} = useI18next();
  const context = React.useContext(I18nextContext);
  const [isSupport, setIsSupport] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    message: '',
    department: hubspotSettings.SUPPORT_EMAIL
  });
  const [showModal, setShowModal] = useState(false);
  const firstNameInputRef = React.useRef(null);
  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
  };
  const isFormValid = formData.email != null && formData.email.trim().length > 0;
  const submit = (e) => {
    e.preventDefault();
    const postSubmit = hubspotSettings.CONTACT_FORM_UPGRADER;
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const body = {

      fields: [
        {
          name: 'firstname',
          value: formData.firstName,
        },
        {
          name: 'lastname',
          value: formData.lastName,
        },
        {
          name: 'company',
          value: formData.companyName,
        },
        {
          name: 'email',
          value: formData.email,
        },
        {
          name: 'phone',
          value: formData.phoneNumber,
        },
        {
          name: 'message',
          value: formData.message,
        },
        {
          name: 'leadsource',
          value: 'Website',
        }
      ],

      context: {
        hutk,
        pageUri,
        pageName,
      },
    }

    fetch(postSubmit, {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      },
    })
        // .then(res => res.json())
        .then((res) => {

          handleSubmitSuccess();
        })
        .catch(err => {
          alert(err)
        })
  };
  let handleSubmitSuccess = () => {
    if (context.language !== 'en') {
      window.location.href = '/' + context.language + '/support/upgrader-program/thank-you/';
    } else {
      window.location.href = '/support/upgrader-program/thank-you/';
    }
  }
  const [infoCurrent, setInfoCurrent] = useState({
    header: t('digilock_americas'),
    address: t('digilock_americas_address'),
    phone: Settings.PHONE_SALES_AMERICAS,
    callSales: isSupport ? t('call_support') : t('call_sales'),
    emailSales: t('email_support'),
    email: Settings.SUPPORT_EMAIL,
    liveChat: t('live_chat'),
    liveChatCopy: t('live_chat_copy'),
    tollFree: t('toll_free'),
    tollFreeNumber: Settings.PHONE_SALES_TOLL_FREE_AMERICAS,
    phoneNumber: Settings.PHONE_SALES_TOLL_FREE_AMERICAS
  });
  const [continent, setContinent] = useState(isBrowser ? sessionStorage.getItem(ContinentSettings.SESSION_CONTINENT) : 'North America');
  function handleInfoCurrentChange() {
    switch (continent) {
      case ContinentSettings.EUROPE:
        setInfoCurrent(prevState => ({
          ...prevState,
          ['header']: t('digilock_europe'),
          ['address']: t('digilock_europe_address'),
          ['phone']: Settings.PHONE_SALES_EUROPE,
          ['email']: Settings.SUPPORT_EMAIL_EUROPE,
          ['tollFree']: '',
          ['tollFreeNumber']: ''
        }));
        break;
      case ContinentSettings.ASIA:
        setInfoCurrent(prevState => ({
          ...prevState,
          ['header']: t('digilock_asia'),
          ['address']: t('digilock_asia_address'),
          ['phone']: Settings.PHONE_SALES_ASIA,
          ['email']: Settings.SUPPORT_EMAIL_ASIA,
          ['tollFree']: '',
          ['tollFreeNumber']: ''
        }));
        break;
      default:
        //code
    }
  }

  useEffect(() => {
    localStorage.setItem('UPGRADE_MODAL', 'seen');
    if (isBrowser) {handleInfoCurrentChange();}
  }, []);

  return (
      <Layout>
        <Seo
            title={t('digilock_upgrader_program')}
            description={t('des_digilock_upgrader_program')}
        />
        <div className="support upgrade-program">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/support/hero-upgrade.png"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Locker room with Digilock locks."
            />
            <h1>
              <Trans>
                upgrader_program
              </Trans>
            </h1>
          </section>
          <section className={'lock-solutions'}>
            <div className="container">
              <h1>
                <Trans>upgrader_headline</Trans>
              </h1>
              <p><Trans>upgrader_para1</Trans></p>
              <div className={'grid'}>
                <div className="col copy-container right">
                  <h2>
                    <Trans>upgrader_list1_headline</Trans>
                  </h2>
                  <ul>
                    <li dangerouslySetInnerHTML={{__html:t('upgrader_list1_1')}} />
                    <li><Trans>upgrader_list1_2</Trans></li>
                    <li><Trans>upgrader_list1_3</Trans></li>
                    <li><Trans>upgrader_list1_4</Trans></li>
                  </ul>
                </div>
                <div className="delimeter"/>
                <div className="col copy-container left">
                  <h2>
                    <Trans>upgrader_list2_headline</Trans>
                  </h2>
                  <ol>
                    <li><Trans>upgrader_list2_1</Trans></li>
                    <li><Trans>upgrader_list2_2</Trans></li>
                    <li><Trans>upgrader_list2_3</Trans></li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className={'contact-us-header'}>
            <div className="container">
              <p><Trans>upgrader_form_headline</Trans></p>
            </div>
          </section>
          <section className="contact-info">
            <div className="container">
              <div className="sales-form show">
                <div className="container">
                  <form id={'supportForm'}
                        action="#"
                        className={'sales-contact-form'}
                        onSubmit={submit}
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                  >
                    <div className="row">
                      <div className="input-container first-name">
                        <label htmlFor={'firstName'}><Trans>first_name</Trans><span style={{fontSize:'x-small'}}> (required)</span></label>
                        <input type="text"
                               name="firstName"
                               id={'firstName'}
                               value={formData.firstName}
                               onChange={e => setFormData({...formData, firstName: e.target.value})}
                               required={'required'}
                               ref={firstNameInputRef}
                        />
                      </div>
                      <div className="input-container last-name">
                        <label htmlFor={'lastName'}><Trans>last_name</Trans><span style={{fontSize:'x-small'}}> (required)</span></label>
                        <input type="text"
                               name="lastName"
                               id={'lastName'}
                               value={formData.lastName}
                               required={'required'}
                               onChange={e => setFormData({...formData, lastName: e.target.value})}
                        />
                      </div>
                    </div>
                    <div className="input-container company-name">
                      <label htmlFor={'companyName'}><Trans>company_name</Trans><span style={{fontSize:'x-small'}}> (required)</span></label>
                      <input type="text"
                             name="companyName"
                             id={'companyName'}
                             value={formData.companyName}
                             required={'required'}
                             onChange={e => setFormData({...formData, companyName: e.target.value})}
                      />
                    </div>

                    <div className="row">
                      <div className="input-container email">
                        <label htmlFor={'email'}><Trans>email</Trans><span style={{fontSize:'x-small'}}> (required)</span></label>
                        <input type="email"
                               name="email"
                               id={'email'}
                               value={formData.email}
                               required={'required'}
                               onChange={e => setFormData({...formData, email: e.target.value})}
                        />
                      </div>
                      <div className="input-container phone-number">
                        <label htmlFor={'phoneNumber'}><Trans>phone_number</Trans></label>
                        <input type="text"
                               id={'phoneNumber'}
                               name="phoneNumber"
                               value={formData.phoneNumber}
                               onChange={e => setFormData({...formData, phoneNumber: e.target.value})}
                        />
                      </div>
                    </div>

                    <div className="input-container message">
                      <label htmlFor="message"><Trans>message</Trans><span style={{fontSize:'x-small'}}> (<Trans>optional</Trans>)</span></label>
                      <textarea name="message"
                                value={formData.message}
                                maxLength={250}
                                onChange={e => setFormData({...formData, message: e.target.value})}
                      >{formData.message}</textarea>
                    </div>
                    <div className="input-container-submit">
                      <input
                          className={'form-submit'}
                          type="submit"
                          aria-disabled={!isFormValid ? 'true' : 'false'}
                          value={'Send'}
                      />
                    </div>

                  </form>
                </div>
              </div>
              <div className="info-current">
                <div className="container">
                  <InfoCardCurrent
                      header={infoCurrent.header}
                      address={infoCurrent.address}
                      phone={infoCurrent.phone}
                      email={infoCurrent.email}
                      callSales={infoCurrent.callSales}
                      emailSales={infoCurrent.emailSales}
                      liveChat={infoCurrent.liveChat}
                      liveChatCopy={infoCurrent.liveChatCopy}
                      tollFree={infoCurrent.tollFree}
                      tollFreeNumber={infoCurrent.tollFreeNumber}
                      phoneNumber={infoCurrent.phoneNumber}
                      geo={continent}
                      showFaq={false}
                      section={'upgrader'}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className={'upgrade-footer'}>
            <div className="container">
              <Link to={'/products/smart-locks/'} className="btn btn--orange">
                LEARN MORE ABOUT OUR LATEST PRODUCTS
              </Link>
              <p>
                <Link to={'/support/upgrader-program/upgrader-terms-conditions/'}>*<Trans>term_conditions_apply</Trans></Link>
              </p>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allSupportGeneralFaqXlsxLockFunctionality {
      edges {
        node {
          id
            title
            content
        }
      }
    }
    allSupportGeneralFaqXlsxSetupProgramming {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  
  allSupportGeneralFaqXlsxTroubleShooting {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  
  allSupportGeneralFaqXlsxOrdersSupport {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  }
`;
