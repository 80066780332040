import React from 'react';
import PropTypes from "prop-types";
import LiveChatButton from "./live-chat-button";
import {useTranslation} from 'gatsby-plugin-react-i18next';

const InfoCardCurrent = (props) => {
  const {t} = useTranslation();
  return (
      <div className="info-card">
        <div>
          <h3>{props.header}</h3>
          <p dangerouslySetInnerHTML={{__html: props.address}}/>
          <p>
            <span>{props.callSales}</span><br/>
            {props.phone}<br/>
            {props.tollFree} {props.tollFreeNumber}
          </p>
        </div>
        <div className={'info-buttons'}>
          {
            props.section !== 'upgrader' ?
                <LiveChatButton liveChat={t('live_chat')} section={props.section} />
                : null
          }

          {
            props.showFaq ?
                <a href="/support/" className={'btn btn--orange faq'}>FAQ</a>
                : null
          }
        </div>
      </div>
  )
};

InfoCardCurrent.propTypes = {
  header: PropTypes.string,
  address: PropTypes.string,
  callSales: PropTypes.string,
  phone: PropTypes.string,
  phoneNumber: PropTypes.string,
  tollFree: PropTypes.string,
  tollFreeNumber: PropTypes.string,
  emailSales: PropTypes.string,
  email: PropTypes.string,
  liveChat: PropTypes.string,
  liveChatCopy: PropTypes.string,
  geo: PropTypes.string,
  handleLiveChat: PropTypes.func,
  showFaq: PropTypes.bool,
  section: PropTypes.string
};

export default InfoCardCurrent;
